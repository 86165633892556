import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import he from "he"

import Layout from "@simplur/gatsby-theme-full-site/src/components/layout"
import SEO from "@simplur/gatsby-theme-full-site/src/components/seo"
import PageSections from "../../components/Interface/PageSections"
import ContactUs from "@simplur/gatsby-theme-full-site/src/components/ContactUs"
import { useThemeOption } from "@simplur/gatsby-theme-full-site/src/hooks"
import Subscribe from "../../components/Subscribe"
import Comments from "@simplur/gatsby-theme-full-site/src/components/comments/index"
import ProductCard from "../../../gatsby-theme-full-site/components/product-card";

const Post = ({
  pageContext: {
    id,
    databaseId,
    postId,
    title,
    content,
    excerpt,
    next,
    prev,
    seo,
    featuredImage,
    date,
    ACFBlogRelatedProducts
  },
  data: {
    wpgraphql: { page: pageData },
  },
}) => {
  const { brandShortName } = useThemeOption()

  let contentToUse = content?.replace(/<form(.|\n)+<\/form>/gm, "")
  if (contentToUse) {
    contentToUse = contentToUse
      .replace(/<!-- imageIcons -->[^]+<!-- endImageIcons -->/gm, "")
      .trim()
  }

  return (
    <Layout>
      <SEO
        json={seo}
        title={title}
        description={`${brandShortName} Blog`}
        featuredImage={featuredImage}
        publishedDate={date}
      />
      <div>
        <PageSections title={title}>
        <Row>
            <Col md={(ACFBlogRelatedProducts?.product) ? "9" : "12"} className="mx-auto">
              {/* <Row className="my-4">
                <Col lg={{ size: 8, offset: 2 }}>
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </Col>
              </Row> */}
              {featuredImage && (
                <Row className="mb-4">
                  <Col>
                    <img
                      className="border-primary rounded shadow"
                      src={featuredImage?.node?.sourceUrl}
                      alt={featuredImage?.node?.altText}
                      srcSet={featuredImage?.node?.srcSet}
                    />
                  </Col>
                </Row>
              )}
          
              <Row>
                <Col>
                  <div dangerouslySetInnerHTML={{ __html: contentToUse }} />
                </Col>
              </Row>

              {pageData?.ACFContactForm?.addContactFormToPage && (
                <Row>
                  <Col md="12" className="mx-auto">
                    <ContactUs />
                  </Col>
                </Row>
              )}
            </Col>
            { ACFBlogRelatedProducts?.product && (
              <Col md="3">
                {ACFBlogRelatedProducts?.product?.map( product => (
                  <ProductCard product={product.product} />
                ) )
                }
              </Col> )
            }
          </Row>
        </PageSections>
        <Comments id={id} databaseId={databaseId} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GET_PAGE_FOR_POST_SINGLE($id: ID!) {
    wpgraphql {
      page(id: $id, idType: ID) {
        id
        databaseId
        pageId
        slug
        status
        ACFContactForm {
          addContactFormToPage
        }
      }
    }
  }
`

export default Post
