import React from "react"
import { Container, Row, Col } from "reactstrap"
import he from "he"
import HeroTitle from "../../../../components/HeroTitle/HeroTitle"

const styles = {
  title: {
    marginBottom: "2rem",
  },
}
const PageSections = ({ children, title, titleTag = "h1" }) => {
  const CustomTitleTag = titleTag

  return (
    <section style={{ ...styles.title, backgroundColor: "" }}>
      <Container>
        {title ? (
          <HeroTitle title={title} />
        ) : null }
        <Row>
          <Col>
            <div>{children}</div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default PageSections
